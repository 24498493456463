/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IProgressIndicatorStyles, IProgressIndicatorStyleProps } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const ProgressIndicatorStyles = (props: IProgressIndicatorStyleProps): Partial<IProgressIndicatorStyles> => {
  const { barHeight = 4, theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { progressBarBackground } = extendedSemanticColors;

  return {
    itemProgress: {
      height: barHeight,
    },
    progressTrack: {
      height: barHeight,
      borderRadius: '2px',
    },
    progressBar: {
      height: barHeight,
      borderTopLeftRadius: '2px',
      borderBottomLeftRadius: '2px',
      background: progressBarBackground,
    },
  };
};
