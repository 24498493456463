/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { ITagItemStyles, ITagItemStyleProps } from '@fluentui/react';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const TagItemStyles = (props: ITagItemStyleProps): Partial<ITagItemStyles> => {
  const { theme } = props;
  if (!theme) {
    return {};
  }

  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { tagIconColor, tagIconBackground } = extendedSemanticColors;

  return {
    root: [
      {
        height: StyleConstants.tagHeight,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
      },
    ],
    text: [{ fontSize: 11, lineHeight: 14 }],
    close: [
      {
        height: 20,
        width: 20,
        padding: 0,
        borderRadius: 10,
        selectors: {
          '.ms-Button-icon': {
            height: 16,
            width: 16,
            fontSize: 16,
          },
          ':active': {
            color: tagIconColor,
            backgroundColor: tagIconBackground,
          },
          ':hover': {
            color: tagIconColor,
            backgroundColor: tagIconBackground,
          },
        },
      },
    ],
  };
};
