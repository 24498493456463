import { OktaVerifyTotp } from './OktaVerifyTotp';
import { VerificationCodeAuthenticator } from './VerificationCodeAuthenticator';
import { OktaPassword } from './OktaPassword';
import { SecurityQuestionEnrollment } from './SecurityQuestionEnrollment';
import { SecurityQuestionVerification } from './SecurityQuestionVerification';
import { AuthenticatorKey } from '../types';
export function getAuthenticator(remediation) {
    const { relatesTo: { value } = {} } = remediation;
    switch (value.key) {
        case AuthenticatorKey.OKTA_PASSWORD:
            return new OktaPassword(value);
        case AuthenticatorKey.SECURITY_QUESTION:
            if (value.contextualData.enrolledQuestion) {
                return new SecurityQuestionVerification(value);
            }
            else {
                return new SecurityQuestionEnrollment(value);
            }
        case AuthenticatorKey.OKTA_VERIFY:
            return new OktaVerifyTotp(value);
        default:
            return new VerificationCodeAuthenticator(value);
    }
}
