/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const fontFamily = '"Noto Sans", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue",sans-serif';

export const borderWidth = '1px';

export const defaultButtonHeight = 32;

export const ComboBoxHeight = 24;

export const fontSize = '12px';
export const headerHeight = '36px';
export const rowHeight = '36px';

export const detailsListCheckWidth = 40;

export const dropdownHeight = 24;

export const navHeight = 32;

export const pivotHeight = 32;

export const tagHeight = 20;

export const basePickerHeight = 24;
