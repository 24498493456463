/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IOverlayStyleProps, IOverlayStyles } from '@fluentui/react';

export const OverlayStyles = (props: IOverlayStyleProps): Partial<IOverlayStyles> => {
  const { isDark } = props;
  return {
    root: [
      isDark && {
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
      },
      !isDark && {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    ],
  };
};
