/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IButtonStyles } from '@fluentui/react';
import { ITheme } from '@fluentui/theme';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const ActionButtonStyles = (theme: ITheme): Partial<IButtonStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;
  const { defaultButtonText, defaultButtonTextDisabled, defaultButtonTextHovered, defaultButtonTextPressed } = extendedSemanticColors;

  return {
    root: [
      {
        color: defaultButtonText,
        fontSize: 12,
      },
    ],
    rootHovered: {
      color: defaultButtonTextHovered,
    },
    rootPressed: {
      color: defaultButtonTextPressed,
    },
    rootDisabled: {
      color: defaultButtonTextDisabled,
    },
  };
};
