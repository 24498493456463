/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IDialogContentStyleProps, IDialogContentStyles } from '@fluentui/react';

import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DialogContentStyles = (props: IDialogContentStyleProps): Partial<IDialogContentStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;
  const { dialogText, dialogSubText } = extendedSemanticColors;

  return {
    subText: [
      {
        marginBottom: 4,
        paddingTop: 0,
        fontSize: 12,
        fontWeight: 300,
        color: dialogSubText,
      },
    ],
    button: [
      {
        width: 16,
        height: 16,
      },
    ],
    title: [
      {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: 24,
        color: dialogText,
        padding: 12,
      },
    ],
    topButton: [
      {
        padding: '12px 12px 0px 0px',
      },
    ],
    inner: [
      {
        padding: 12,
      },
    ],
    innerContent: [
      // {
      //   paddingLeft: 12,
      //   paddingRight: 8,
      // },
    ],
  };
};
