/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IBasePickerStyles, IBasePickerStyleProps } from '@fluentui/react';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const TagPickerStyles = (props: IBasePickerStyleProps): Partial<IBasePickerStyles> => {
  const { disabled, theme } = props;
  if (!theme) {
    return {};
  }
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { inputBorderFocused } = extendedSemanticColors;

  return {
    root: {
      selectors: {
        '.ms-BasePicker-text::after': {
          borderColor: inputBorderFocused,
          borderWidth: 1,
        },
      },
    },
    text: [
      {
        minHeight: StyleConstants.basePickerHeight,
      },
    ],
    input: [
      {
        height: StyleConstants.basePickerHeight,
        lineHeight: StyleConstants.basePickerHeight - 2,
        fontSize: 11,
      },
      disabled && {
        display: 'none',
      },
    ],
  };
};
