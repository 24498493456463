/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IBreadcrumbStyleProps, IBreadcrumbStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const BreadcrumbStyles = (props: IBreadcrumbStyleProps): Partial<IBreadcrumbStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { navBackground, navText } = extendedSemanticColors;

  return {
    root: {
      selectors: {
        '.ms-Link ': {
          color: navText,
        },
        '.ms-Link:hover': {
          textDecoration: 'none',
          background: navBackground,
        },
      },
    },
    chevron: {
      color: navText,
    },

    itemLink: [
      'ms-Breadcrumb-itemLink',

      {
        fontWeight: 100,
      },
    ],
  };
};
