/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

// Consider changing backgroundColor to 'white' and selected background to '#F4F4F4' per design spec.
// This currently conflicts with sideback background color which is gray

import { INavStyleProps, INavStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';
import * as StyleConstants from '../Constants';

export const NavStyles = (props: INavStyleProps): Partial<INavStyles> => {
  const { isDisabled, isSelected, theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { navBackground, navSelectedBackground, navSelectedText, navText } = extendedSemanticColors;

  return {
    link: [
      {
        height: StyleConstants.navHeight,
        // paddingLeft: '15px',
        backgroundColor: navBackground,
        color: navText,
        selectors: !isDisabled
          ? {
              '.ms-Button-icon': {
                color: navText,
              },
              ':hover .ms-Button-icon': {
                color: navText,
              },
              '&:active, &:active:hover': {
                color: navSelectedText,
              },
              '.ms-Nav-compositeLink:hover &': {
                color: navText,
                backgroundColor: navSelectedBackground,
              },
            }
          : {},
      },
      isSelected && {
        backgroundColor: navSelectedBackground,
        color: navSelectedText,
        fontWeight: 400,
        selectors: {
          '&:after': {
            content: '',
          },
          '.ms-Button-icon': {
            color: navSelectedText,
          },
          ':hover .ms-Button-icon': {
            color: navSelectedText,
          },
          '.ms-Nav-compositeLink:hover &': {
            color: navSelectedText,
          },
        },
      },
    ],
    navItem: {
      height: StyleConstants.navHeight,
    },
    navItems: {
      marginTop: 0,
    },
  };
};
