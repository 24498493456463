/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IButtonStyles, ITheme } from '@fluentui/react';

import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const IconButtonStyles = (theme: ITheme): Partial<IButtonStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { iconColor, iconBackground } = extendedSemanticColors;

  return {
    root: {
      backgroundColor: iconBackground,
      color: iconColor,
    },
    rootHovered: {
      backgroundColor: iconBackground,
      color: iconColor,
    },
    rootPressed: {
      backgroundColor: iconBackground,
    },
  };
};
