import { Authenticator } from './Authenticator';
export class OktaPassword extends Authenticator {
    canVerify(values) {
        return !!values.password;
    }
    mapCredentials(values) {
        return { passcode: values.password };
    }
    getInputs(idxRemediationValue) {
        var _a;
        return Object.assign(Object.assign({}, (_a = idxRemediationValue.form) === null || _a === void 0 ? void 0 : _a.value[0]), { name: 'password', type: 'string', required: idxRemediationValue.required });
    }
}
