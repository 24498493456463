/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IDatePickerStyles, IDatePickerStyleProps } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DatePickerStyles = (props: IDatePickerStyleProps): Partial<IDatePickerStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;

  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;
  const { inputBorderFocused } = extendedSemanticColors;

  return {
    icon: [
      {
        top: -4,
        lineHeight: 18,
        fontSize: 16,
      },
    ],
    root: [
      {
        selectors: {
          '.ms-TextField-fieldGroup::after': {
            borderColor: inputBorderFocused,
            borderWidth: 1,
          },
        },
      },
    ],
  };
};
