/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as React from 'react';
import type { ThemeProviderProps } from '@fluentui/react';
import { ThemeProvider, Theme } from '@fluentui/react';

import { AOneUnityThemeLight } from './theme/AOneUnityThemeLight';
import { initIcons } from './icons';

initIcons();

export interface AOneUnityThemeProps extends Omit<ThemeProviderProps, "theme"> {
  children?: React.ReactNode;
  theme: Theme;
}

export const AOneUnityTheme = (props: AOneUnityThemeProps) => {

  const { theme, children, ...restProps } = props;

  let selectedTheme = theme;

  if(!selectedTheme) {
    selectedTheme = AOneUnityThemeLight;
  }
  return (
    <ThemeProvider theme={selectedTheme} {...restProps}>
      {children}
    </ThemeProvider>
  );
};

AOneUnityTheme.displayName = 'AOneUnityTheme';
