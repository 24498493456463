/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IButtonStyles } from '@fluentui/react';
import { ITheme } from '@fluentui/theme';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const PrimaryButtonStyles = (theme: ITheme): Partial<IButtonStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { primaryButtonBorderHovered, primaryButtonBorderDisabled, primaryButtonBorderPressed } = extendedSemanticColors;

  const {
    primaryButtonBackground,
    primaryButtonBackgroundDisabled,
    primaryButtonBackgroundHovered,
    primaryButtonBackgroundPressed,
    primaryButtonText,
    primaryButtonBorder,
    primaryButtonTextDisabled,
    primaryButtonTextHovered,
    primaryButtonTextPressed,
  } = semanticColors;

  return {
    root: {
      borderRadius: StyleConstants.defaultButtonHeight / 2,
      padding: '8px 16px',
      background: primaryButtonBackground,
      color: primaryButtonText,
      border: `${StyleConstants.borderWidth} solid ${primaryButtonBorder}`,

      selectors: {
        '.ms-Fabric--isFocusVisible &:focus::after': {
          outline: 'none',
        },
      },
    },

    rootHovered: {
      background: primaryButtonBackgroundHovered,
      color: primaryButtonTextHovered,
      border: `${StyleConstants.borderWidth} solid ${primaryButtonBorderHovered}`,
    },
    rootPressed: {
      background: primaryButtonBackgroundPressed,
      color: primaryButtonTextPressed,
      border: `${StyleConstants.borderWidth} solid ${primaryButtonBorderPressed}`,
    },
    rootDisabled: {
      background: primaryButtonBackgroundDisabled,
      color: primaryButtonTextDisabled,
      border: `${StyleConstants.borderWidth} solid ${primaryButtonBorderDisabled}`,
    },
    label: [
      {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: StyleConstants.defaultButtonHeight / 2,
        margin: 0,
        padding: 0,
      },
    ],
  };
};
