/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ILabelStyleProps, ILabelStyles } from '@fluentui/react/';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const LabelStyles = (props: ILabelStyleProps): Partial<ILabelStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { labelText } = extendedSemanticColors;

  return {
    root: [
      {
        fontSize: 14,
        color: labelText,
        fontWeight: 400,
      },
    ],
  };
};
