import { AuthenticationFlow } from './AuthenticationFlow';
import { PasswordRecoveryFlow } from './PasswordRecoveryFlow';
import { RegistrationFlow } from './RegistrationFlow';
export function getFlowSpecification(oktaAuth, flow = 'proceed') {
    let remediators, actions;
    switch (flow) {
        case 'register':
        case 'signup':
        case 'enrollProfile':
            remediators = RegistrationFlow;
            break;
        case 'recoverPassword':
        case 'resetPassword':
            remediators = PasswordRecoveryFlow;
            actions = [
                'currentAuthenticator-recover',
                'currentAuthenticatorEnrollment-recover'
            ];
            break;
        default:
            // authenticate
            remediators = AuthenticationFlow;
            break;
    }
    return { flow, remediators, actions };
}
