/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ISpinnerStyleProps, ISpinnerStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const SpinnerStyles = (props: ISpinnerStyleProps): Partial<ISpinnerStyles> => {

  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  return {
    circle: [
      {
        borderColor: extendedSemanticColors.spinnerCircleColor
      },
    ],
    label: [
      { 
        color: extendedSemanticColors.spinnerLabelColor
      }
    ]
  };
};
