/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { registerIcons, setIconOptions } from '@fluentui/react';
import { createUnityIcon } from './createUnityIcon';

/* --------------------------------------------------------------------------------
 * ********************************* WARNING **************************************
 *
 * DON'T MODIFY THIS FILE BY HAND
 * ALL YOUR CHANGES WILL BE DISCARDED IN npm prepublishOnly STEP
 * IN CASE OF ANY MODIFICATION UPDATE scripts/initIcons.ts.tpl
 *
 * ********************************* WARNING **************************************
 * --------------------------------------------------------------------------------*/

export function initIcons() {
  setIconOptions({
    disableWarnings: true,
  });

  /*
  - Replace specific fluentui icons with unity icons.
  */
  registerIcons({
    icons: {
      Blocked: createUnityIcon('terminate'),
      Blocked2: createUnityIcon('circle_minus'),
      BoxAdditionSolid: createUnityIcon('box_plus'),
      BoxSubtractSolid: createUnityIcon('box_minus'),
      Callout: createUnityIcon('chat'),
      Camera: createUnityIcon('screencapture'),
      Cancel: createUnityIcon('close'),
      CheckMark: createUnityIcon('check'),
      ChevronDown: createUnityIcon('down_chevron'),
      ChevronLeft: createUnityIcon('left_chevron'),
      ChevronRight: createUnityIcon('right_chevron'),
      ChevronUp: createUnityIcon('up_chevron'),
      ChromeClose: createUnityIcon('close'),
      CircleAddition: createUnityIcon('circle_plus'),
      Clear: createUnityIcon('close'),
      CompletedSolid: createUnityIcon('circle_check'),
      ConnectContacts: createUnityIcon('user_groups'),
      Contact: createUnityIcon('user'),
      Database: createUnityIcon('db_alt'),
      Delete: createUnityIcon('trash_can'),
      DoubleChevronDown: createUnityIcon('double_down_chevron'),
      DoubleChevronUp: createUnityIcon('double_up_chevron'),
      Edit: createUnityIcon('edit_alt'),
      EditPencil: createUnityIcon('edit'),
      EmptyRecycleBin: createUnityIcon('trash_delete'),
      FabricNewFolder: createUnityIcon('folder_add'),
      FabricFolderFill: createUnityIcon('folder'),
      FavoriteStar: createUnityIcon('star'),
      FavoriteStarFill: createUnityIcon('star_selected'),
      FileList: createUnityIcon('load_profile'),
      Forward: createUnityIcon('right_arrow'),
      GiftboxOpen: createUnityIcon('magic_wand'),
      Group: createUnityIcon('user_groups'),
      Info: createUnityIcon('info_circle'),
      InfoSolid: createUnityIcon('info_circle'),
      MiniContract: createUnityIcon('compress'),
      MiniExpand: createUnityIcon('full_screen'),
      More: createUnityIcon('ellipses_horizontal'),
      OpenFile: createUnityIcon('file'),
      OpenInNewWindow: createUnityIcon('open_new_window'),
      Page: createUnityIcon('file'),
      ProgressRingDots: createUnityIcon('circle_markup'),
      RecycleBin: createUnityIcon('trash_can'),
      RedEye: createUnityIcon('view'),
      Rename: createUnityIcon('richtext_chart'),
      Ringer: createUnityIcon('notification'),
      Right: createUnityIcon('right'),
      RevToggleKey: createUnityIcon('trash_restore'),
      Settings: createUnityIcon('gear'),
      StatusCircleQuestionMark: createUnityIcon('help'),
      StatusErrorFull: createUnityIcon('error'),
      Suitcase: createUnityIcon('jobs'),
      Thumbtack: createUnityIcon('pin'),
      Warning: createUnityIcon('error'),
      WarningTriangle: createUnityIcon('warning_triangle_fill'),
      WindowEdit: createUnityIcon('monitor'),
      ZipFolder: createUnityIcon('file_archive'),

      // From Template Start
      JoinIntersect: createUnityIcon('join_intersect'),
      unity_join_intersect: createUnityIcon('join_intersect'),
      ExpandVertical: createUnityIcon('expand_vertical'),
      unity_expand_vertical: createUnityIcon('expand_vertical'),
      Aggregation: createUnityIcon('aggregation'),
      unity_aggregation: createUnityIcon('aggregation'),
      Bookmark: createUnityIcon('bookmark'),
      unity_bookmark: createUnityIcon('bookmark'),
      Branch: createUnityIcon('branch'),
      unity_branch: createUnityIcon('branch'),
      Breakdown: createUnityIcon('breakdown'),
      unity_breakdown: createUnityIcon('breakdown'),
      Calculate: createUnityIcon('calculate'),
      unity_calculate: createUnityIcon('calculate'),
      Change: createUnityIcon('change'),
      unity_change: createUnityIcon('change'),
      Columns: createUnityIcon('columns'),
      unity_columns: createUnityIcon('columns'),
      DataInput: createUnityIcon('data_input'),
      unity_data_input: createUnityIcon('data_input'),
      DataOutput: createUnityIcon('data_output'),
      unity_data_output: createUnityIcon('data_output'),
      ErrorCircle: createUnityIcon('error_circle'),
      unity_error_circle: createUnityIcon('error_circle'),
      Details: createUnityIcon('details'),
      unity_details: createUnityIcon('details'),
      Expand: createUnityIcon('expand'),
      unity_expand: createUnityIcon('expand'),
      JoinIntersectLeft: createUnityIcon('join_intersect_left'),
      unity_join_intersect_left: createUnityIcon('join_intersect_left'),
      JoinIntersectRight: createUnityIcon('join_intersect_right'),
      unity_join_intersect_right: createUnityIcon('join_intersect_right'),
      JoinLeft: createUnityIcon('join_left'),
      unity_join_left: createUnityIcon('join_left'),
      JoinOutline: createUnityIcon('join_outline'),
      unity_join_outline: createUnityIcon('join_outline'),
      JoinRight: createUnityIcon('join_right'),
      unity_join_right: createUnityIcon('join_right'),
      JoinUnion: createUnityIcon('join_union'),
      unity_join_union: createUnityIcon('join_union'),
      Join: createUnityIcon('join'),
      unity_join: createUnityIcon('join'),
      Latitude: createUnityIcon('latitude'),
      unity_latitude: createUnityIcon('latitude'),
      LoadingSpinner: createUnityIcon('loading_spinner'),
      unity_loading_spinner: createUnityIcon('loading_spinner'),
      Longitude: createUnityIcon('longitude'),
      unity_longitude: createUnityIcon('longitude'),
      Metronome: createUnityIcon('metronome'),
      unity_metronome: createUnityIcon('metronome'),
      NumericFormula: createUnityIcon('numeric_formula'),
      unity_numeric_formula: createUnityIcon('numeric_formula'),
      NumericSeries: createUnityIcon('numeric_series'),
      unity_numeric_series: createUnityIcon('numeric_series'),
      Palette: createUnityIcon('palette'),
      unity_palette: createUnityIcon('palette'),
      ParametersOutline: createUnityIcon('parameters_outline'),
      unity_parameters_outline: createUnityIcon('parameters_outline'),
      Plug: createUnityIcon('plug'),
      unity_plug: createUnityIcon('plug'),
      Python: createUnityIcon('python'),
      unity_python: createUnityIcon('python'),
      Rows: createUnityIcon('rows'),
      unity_rows: createUnityIcon('rows'),
      Parameters: createUnityIcon('parameters'),
      unity_parameters: createUnityIcon('parameters'),
      SoundOff: createUnityIcon('sound_off'),
      unity_sound_off: createUnityIcon('sound_off'),
      Shapes: createUnityIcon('shapes'),
      unity_shapes: createUnityIcon('shapes'),
      SoundOn: createUnityIcon('sound_on'),
      unity_sound_on: createUnityIcon('sound_on'),
      Sync: createUnityIcon('sync'),
      unity_sync: createUnityIcon('sync'),
      TextFormula: createUnityIcon('text_formula'),
      unity_text_formula: createUnityIcon('text_formula'),
      WarningCircleFill: createUnityIcon('warning_circle_fill'),
      unity_warning_circle_fill: createUnityIcon('warning_circle_fill'),
      WarningTriangleFill: createUnityIcon('warning_triangle_fill'),
      unity_warning_triangle_fill: createUnityIcon('warning_triangle_fill'),
      WarningTriangleOutline: createUnityIcon('warning_triangle_outline'),
      unity_warning_triangle_outline: createUnityIcon('warning_triangle_outline'),
      ZoomIn: createUnityIcon('zoom_in'),
      unity_zoom_in: createUnityIcon('zoom_in'),
      ZoomOut: createUnityIcon('zoom_out'),
      unity_zoom_out: createUnityIcon('zoom_out'),
      WarningCircleOutline: createUnityIcon('warning_circle_outline'),
      unity_warning_circle_outline: createUnityIcon('warning_circle_outline'),
      Resolution: createUnityIcon('resolution'),
      unity_resolution: createUnityIcon('resolution'),
      AppMenu: createUnityIcon('app_menu'),
      unity_app_menu: createUnityIcon('app_menu'),
      EllipsesHorizontal: createUnityIcon('ellipses_horizontal'),
      unity_ellipses_horizontal: createUnityIcon('ellipses_horizontal'),
      EllipsesVertical: createUnityIcon('ellipses_vertical'),
      unity_ellipses_vertical: createUnityIcon('ellipses_vertical'),
      FileArchive: createUnityIcon('file_archive'),
      unity_file_archive: createUnityIcon('file_archive'),
      File: createUnityIcon('file'),
      unity_file: createUnityIcon('file'),
      Paste: createUnityIcon('paste'),
      unity_paste: createUnityIcon('paste'),
      Pin: createUnityIcon('pin'),
      unity_pin: createUnityIcon('pin'),
      SortDown: createUnityIcon('sort_down'),
      unity_sort_down: createUnityIcon('sort_down'),
      SortUp: createUnityIcon('sort_up'),
      unity_sort_up: createUnityIcon('sort_up'),
      Sort: createUnityIcon('sort'),
      unity_sort: createUnityIcon('sort'),
      TrashDelete: createUnityIcon('trash_delete'),
      unity_trash_delete: createUnityIcon('trash_delete'),
      TrashEmpty: createUnityIcon('trash_empty'),
      unity_trash_empty: createUnityIcon('trash_empty'),
      TrashFull: createUnityIcon('trash_full'),
      unity_trash_full: createUnityIcon('trash_full'),
      TrashRestore: createUnityIcon('trash_restore'),
      unity_trash_restore: createUnityIcon('trash_restore'),
      ThreeD: createUnityIcon('three_d'),
      unity_three_d: createUnityIcon('three_d'),
      Image: createUnityIcon('image'),
      unity_image: createUnityIcon('image'),
      Pivot: createUnityIcon('pivot'),
      unity_pivot: createUnityIcon('pivot'),
      SaveAs: createUnityIcon('Save_as'),
      unity_Save_as: createUnityIcon('Save_as'),
      Save: createUnityIcon('Save'),
      unity_Save: createUnityIcon('Save'),
      ScatterChart: createUnityIcon('scatter_chart'),
      unity_scatter_chart: createUnityIcon('scatter_chart'),
      RankingVertChart: createUnityIcon('ranking_vert_chart'),
      unity_ranking_vert_chart: createUnityIcon('ranking_vert_chart'),
      RankingHorizChart: createUnityIcon('ranking_horiz_chart'),
      unity_ranking_horiz_chart: createUnityIcon('ranking_horiz_chart'),
      HistogramChart: createUnityIcon('histogram_chart'),
      unity_histogram_chart: createUnityIcon('histogram_chart'),
      DbProgress: createUnityIcon('db_progress'),
      unity_db_progress: createUnityIcon('db_progress'),
      DbAws: createUnityIcon('db_aws'),
      unity_db_aws: createUnityIcon('db_aws'),
      Screencapture: createUnityIcon('screencapture'),
      unity_screencapture: createUnityIcon('screencapture'),
      Flag: createUnityIcon('flag'),
      unity_flag: createUnityIcon('flag'),
      ThumbsDownFilled: createUnityIcon('thumbs_down_filled'),
      unity_thumbs_down_filled: createUnityIcon('thumbs_down_filled'),
      ThumbsUpFilled: createUnityIcon('thumbs_up_filled'),
      unity_thumbs_up_filled: createUnityIcon('thumbs_up_filled'),
      CircleFilled: createUnityIcon('circle_filled'),
      unity_circle_filled: createUnityIcon('circle_filled'),
      AdvForecast: createUnityIcon('adv_forecast'),
      unity_adv_forecast: createUnityIcon('adv_forecast'),
      AdvLearn: createUnityIcon('adv_learn'),
      unity_adv_learn: createUnityIcon('adv_learn'),
      AdvScoring: createUnityIcon('adv_scoring'),
      unity_adv_scoring: createUnityIcon('adv_scoring'),
      AdvWhatIf: createUnityIcon('adv_what_if'),
      unity_adv_what_if: createUnityIcon('adv_what_if'),
      DbDynamo: createUnityIcon('db_dynamo'),
      unity_db_dynamo: createUnityIcon('db_dynamo'),
      DbMqtt: createUnityIcon('db_mqtt'),
      unity_db_mqtt: createUnityIcon('db_mqtt'),
      DbMsaccess: createUnityIcon('db_msaccess'),
      unity_db_msaccess: createUnityIcon('db_msaccess'),
      DbMsdynamics: createUnityIcon('db_msdynamics'),
      unity_db_msdynamics: createUnityIcon('db_msdynamics'),
      DbTimescale: createUnityIcon('db_timescale'),
      unity_db_timescale: createUnityIcon('db_timescale'),
      DbWebsockets: createUnityIcon('db_websockets'),
      unity_db_websockets: createUnityIcon('db_websockets'),
      Hide: createUnityIcon('hide'),
      unity_hide: createUnityIcon('hide'),
      Show: createUnityIcon('show'),
      unity_show: createUnityIcon('show'),
      FastForward: createUnityIcon('fast_forward'),
      unity_fast_forward: createUnityIcon('fast_forward'),
      Pause: createUnityIcon('pause'),
      unity_pause: createUnityIcon('pause'),
      Play: createUnityIcon('play'),
      unity_play: createUnityIcon('play'),
      SkipForward: createUnityIcon('skip_forward'),
      unity_skip_forward: createUnityIcon('skip_forward'),
      SkipRewind: createUnityIcon('skip_rewind'),
      unity_skip_rewind: createUnityIcon('skip_rewind'),
      Stop: createUnityIcon('stop'),
      unity_stop: createUnityIcon('stop'),
      DbIbm: createUnityIcon('db_ibm'),
      unity_db_ibm: createUnityIcon('db_ibm'),
      DbSpark: createUnityIcon('db_spark'),
      unity_db_spark: createUnityIcon('db_spark'),
      Excel: createUnityIcon('excel'),
      unity_excel: createUnityIcon('excel'),
      Relate: createUnityIcon('relate'),
      unity_relate: createUnityIcon('relate'),
      Token: createUnityIcon('token'),
      unity_token: createUnityIcon('token'),
      UserAdmin: createUnityIcon('user_admin'),
      unity_user_admin: createUnityIcon('user_admin'),
      Schedule: createUnityIcon('schedule'),
      unity_schedule: createUnityIcon('schedule'),
      Gplus: createUnityIcon('gplus'),
      unity_gplus: createUnityIcon('gplus'),
      DbCandi: createUnityIcon('db_candi'),
      unity_db_candi: createUnityIcon('db_candi'),
      DbCarriots: createUnityIcon('db_carriots'),
      unity_db_carriots: createUnityIcon('db_carriots'),
      DbIot: createUnityIcon('db_iot'),
      unity_db_iot: createUnityIcon('db_iot'),
      DataFormat: createUnityIcon('data_format'),
      unity_data_format: createUnityIcon('data_format'),
      FormChart: createUnityIcon('form_chart'),
      unity_form_chart: createUnityIcon('form_chart'),
      Compress: createUnityIcon('compress'),
      unity_compress: createUnityIcon('compress'),
      CsvAlt1: createUnityIcon('csv_alt1'),
      unity_csv_alt1: createUnityIcon('csv_alt1'),
      CsvAlt: createUnityIcon('csv_alt'),
      unity_csv_alt: createUnityIcon('csv_alt'),
      DashletChart: createUnityIcon('dashlet_chart'),
      unity_dashlet_chart: createUnityIcon('dashlet_chart'),
      DbBigquery: createUnityIcon('db_bigquery'),
      unity_db_bigquery: createUnityIcon('db_bigquery'),
      DbCrm: createUnityIcon('db_crm'),
      unity_db_crm: createUnityIcon('db_crm'),
      DbMaria: createUnityIcon('db_maria'),
      unity_db_maria: createUnityIcon('db_maria'),
      DbOracle: createUnityIcon('db_oracle'),
      unity_db_oracle: createUnityIcon('db_oracle'),
      DbPostgres: createUnityIcon('db_postgres'),
      unity_db_postgres: createUnityIcon('db_postgres'),
      DbRedshift: createUnityIcon('db_redshift'),
      unity_db_redshift: createUnityIcon('db_redshift'),
      DbSalesforce: createUnityIcon('db_salesforce'),
      unity_db_salesforce: createUnityIcon('db_salesforce'),
      DbSqlServer: createUnityIcon('db_sql_server'),
      unity_db_sql_server: createUnityIcon('db_sql_server'),
      DbSun: createUnityIcon('db_sun'),
      unity_db_sun: createUnityIcon('db_sun'),
      DbVertica: createUnityIcon('db_vertica'),
      unity_db_vertica: createUnityIcon('db_vertica'),
      FunnelChart: createUnityIcon('funnel_chart'),
      unity_funnel_chart: createUnityIcon('funnel_chart'),
      Link: createUnityIcon('link'),
      unity_link: createUnityIcon('link'),
      Randomize: createUnityIcon('randomize'),
      unity_randomize: createUnityIcon('randomize'),
      Refresh: createUnityIcon('refresh'),
      unity_refresh: createUnityIcon('refresh'),
      SourceCsv: createUnityIcon('source_csv'),
      unity_source_csv: createUnityIcon('source_csv'),
      Surface3DChart: createUnityIcon('surface_3d_chart'),
      unity_surface_3d_chart: createUnityIcon('surface_3d_chart'),
      TextBold: createUnityIcon('text_bold'),
      unity_text_bold: createUnityIcon('text_bold'),
      TextItalic: createUnityIcon('text_italic'),
      unity_text_italic: createUnityIcon('text_italic'),
      TextJustifyLeft: createUnityIcon('text_justify_left'),
      unity_text_justify_left: createUnityIcon('text_justify_left'),
      TextJustifyMiddle: createUnityIcon('text_justify_middle'),
      unity_text_justify_middle: createUnityIcon('text_justify_middle'),
      TextJustifyRight: createUnityIcon('text_justify_right'),
      unity_text_justify_right: createUnityIcon('text_justify_right'),
      TreemapChart: createUnityIcon('treemap_chart'),
      unity_treemap_chart: createUnityIcon('treemap_chart'),
      TypeInteger: createUnityIcon('type_integer'),
      unity_type_integer: createUnityIcon('type_integer'),
      TypeString: createUnityIcon('type_string'),
      unity_type_string: createUnityIcon('type_string'),
      WordcloudChart: createUnityIcon('wordcloud_chart'),
      unity_wordcloud_chart: createUnityIcon('wordcloud_chart'),
      '3DbarChart': createUnityIcon('3dbar_chart'),
      unity_3dbar_chart: createUnityIcon('3dbar_chart'),
      Acrobat: createUnityIcon('acrobat'),
      unity_acrobat: createUnityIcon('acrobat'),
      Add: createUnityIcon('add'),
      unity_add: createUnityIcon('add'),
      RichtextChart: createUnityIcon('richtext_chart'),
      unity_richtext_chart: createUnityIcon('richtext_chart'),
      ArrowCircle: createUnityIcon('arrow_circle'),
      unity_arrow_circle: createUnityIcon('arrow_circle'),
      Asterix: createUnityIcon('asterix'),
      unity_asterix: createUnityIcon('asterix'),
      Avg: createUnityIcon('avg'),
      unity_avg: createUnityIcon('avg'),
      Aws: createUnityIcon('aws'),
      unity_aws: createUnityIcon('aws'),
      BarChart: createUnityIcon('bar_chart'),
      unity_bar_chart: createUnityIcon('bar_chart'),
      Checkbox: createUnityIcon('checkbox'),
      unity_checkbox: createUnityIcon('checkbox'),
      BoxDown: createUnityIcon('box_down'),
      unity_box_down: createUnityIcon('box_down'),
      BoxLeft: createUnityIcon('box_left'),
      unity_box_left: createUnityIcon('box_left'),
      BoxMinus: createUnityIcon('box_minus'),
      unity_box_minus: createUnityIcon('box_minus'),
      BoxplotChart: createUnityIcon('boxplot_chart'),
      unity_boxplot_chart: createUnityIcon('boxplot_chart'),
      BoxPlus: createUnityIcon('box_plus'),
      unity_box_plus: createUnityIcon('box_plus'),
      BoxRight: createUnityIcon('box_right'),
      unity_box_right: createUnityIcon('box_right'),
      BoxUp: createUnityIcon('box_up'),
      unity_box_up: createUnityIcon('box_up'),
      Box: createUnityIcon('box'),
      unity_box: createUnityIcon('box'),
      Calendar: createUnityIcon('calendar'),
      unity_calendar: createUnityIcon('calendar'),
      Chat: createUnityIcon('chat'),
      unity_chat: createUnityIcon('chat'),
      SelectNone: createUnityIcon('select_none'),
      unity_select_none: createUnityIcon('select_none'),
      SelectAll: createUnityIcon('select_all'),
      unity_select_all: createUnityIcon('select_all'),
      SelectInverse: createUnityIcon('select_inverse'),
      unity_select_inverse: createUnityIcon('select_inverse'),
      Check: createUnityIcon('check'),
      unity_check: createUnityIcon('check'),
      CircleCheck: createUnityIcon('circle_check'),
      unity_circle_check: createUnityIcon('circle_check'),
      CircleMarkup: createUnityIcon('circle_markup'),
      unity_circle_markup: createUnityIcon('circle_markup'),
      CircleMinus: createUnityIcon('circle_minus'),
      unity_circle_minus: createUnityIcon('circle_minus'),
      CirclePlus: createUnityIcon('circle_plus'),
      unity_circle_plus: createUnityIcon('circle_plus'),
      Circle: createUnityIcon('circle'),
      unity_circle: createUnityIcon('circle'),
      ClearDup: createUnityIcon('clear'),
      unity_clear: createUnityIcon('clear'),
      Clock: createUnityIcon('clock'),
      unity_clock: createUnityIcon('clock'),
      Close: createUnityIcon('close'),
      unity_close: createUnityIcon('close'),
      DbMonet: createUnityIcon('db_monet'),
      unity_db_monet: createUnityIcon('db_monet'),
      Cloud: createUnityIcon('cloud'),
      unity_cloud: createUnityIcon('cloud'),
      Compass: createUnityIcon('compass'),
      unity_compass: createUnityIcon('compass'),
      Compute: createUnityIcon('compute'),
      unity_compute: createUnityIcon('compute'),
      ConicalFlask: createUnityIcon('conical_flask'),
      unity_conical_flask: createUnityIcon('conical_flask'),
      Copy: createUnityIcon('copy'),
      unity_copy: createUnityIcon('copy'),
      Css: createUnityIcon('css'),
      unity_css: createUnityIcon('css'),
      Csv: createUnityIcon('csv'),
      unity_csv: createUnityIcon('csv'),
      Cube1: createUnityIcon('cube_1'),
      unity_cube_1: createUnityIcon('cube_1'),
      Cube2: createUnityIcon('cube_2'),
      unity_cube_2: createUnityIcon('cube_2'),
      CubeDoc: createUnityIcon('cube_doc'),
      unity_cube_doc: createUnityIcon('cube_doc'),
      Cube: createUnityIcon('cube'),
      unity_cube: createUnityIcon('cube'),
      Currency: createUnityIcon('currency'),
      unity_currency: createUnityIcon('currency'),
      Cursor: createUnityIcon('cursor'),
      unity_cursor: createUnityIcon('cursor'),
      Cut: createUnityIcon('cut'),
      unity_cut: createUnityIcon('cut'),
      Dashboard: createUnityIcon('dashboard'),
      unity_dashboard: createUnityIcon('dashboard'),
      DbAlt: createUnityIcon('db_alt'),
      unity_db_alt: createUnityIcon('db_alt'),
      Db: createUnityIcon('db'),
      unity_db: createUnityIcon('db'),
      DateTime: createUnityIcon('date_time'),
      unity_date_time: createUnityIcon('date_time'),
      DeltaDrillChart: createUnityIcon('delta_drill_chart'),
      unity_delta_drill_chart: createUnityIcon('delta_drill_chart'),
      Document: createUnityIcon('document'),
      unity_document: createUnityIcon('document'),
      Dollar: createUnityIcon('dollar'),
      unity_dollar: createUnityIcon('dollar'),
      DbMysql: createUnityIcon('db_mysql'),
      unity_db_mysql: createUnityIcon('db_mysql'),
      DonutChart: createUnityIcon('donut_chart'),
      unity_donut_chart: createUnityIcon('donut_chart'),
      DoubleDownChevron: createUnityIcon('double_down_chevron'),
      unity_double_down_chevron: createUnityIcon('double_down_chevron'),
      DoubleLeftChevron: createUnityIcon('double_left_chevron'),
      unity_double_left_chevron: createUnityIcon('double_left_chevron'),
      DoubleRightChevron: createUnityIcon('double_right_chevron'),
      unity_double_right_chevron: createUnityIcon('double_right_chevron'),
      DoubleUpChevron: createUnityIcon('double_up_chevron'),
      unity_double_up_chevron: createUnityIcon('double_up_chevron'),
      DownArrow: createUnityIcon('down_arrow'),
      unity_down_arrow: createUnityIcon('down_arrow'),
      DownChevron: createUnityIcon('down_chevron'),
      unity_down_chevron: createUnityIcon('down_chevron'),
      DownSmall: createUnityIcon('down_small'),
      unity_down_small: createUnityIcon('down_small'),
      Down: createUnityIcon('down'),
      unity_down: createUnityIcon('down'),
      DownloadAlt: createUnityIcon('download_alt'),
      unity_download_alt: createUnityIcon('download_alt'),
      DownloadAlt1: createUnityIcon('download_alt1'),
      unity_download_alt1: createUnityIcon('download_alt1'),
      DownloadFiles: createUnityIcon('download_files'),
      unity_download_files: createUnityIcon('download_files'),
      Download: createUnityIcon('download'),
      unity_download: createUnityIcon('download'),
      DrillDown: createUnityIcon('drill_down'),
      unity_drill_down: createUnityIcon('drill_down'),
      Drive: createUnityIcon('drive'),
      unity_drive: createUnityIcon('drive'),
      Dropbox: createUnityIcon('dropbox'),
      unity_dropbox: createUnityIcon('dropbox'),
      Duplicate: createUnityIcon('duplicate'),
      unity_duplicate: createUnityIcon('duplicate'),
      EditAlt: createUnityIcon('edit_alt'),
      unity_edit_alt: createUnityIcon('edit_alt'),
      EditDup: createUnityIcon('edit'),
      unity_edit: createUnityIcon('edit'),
      Embed: createUnityIcon('embed'),
      unity_embed: createUnityIcon('embed'),
      Error: createUnityIcon('error'),
      unity_error: createUnityIcon('error'),
      Euro: createUnityIcon('euro'),
      unity_euro: createUnityIcon('euro'),
      ExchangeDoc: createUnityIcon('exchange_doc'),
      unity_exchange_doc: createUnityIcon('exchange_doc'),
      ExpandHorizontal: createUnityIcon('expand_horizontal'),
      unity_expand_horizontal: createUnityIcon('expand_horizontal'),
      Facebook: createUnityIcon('facebook'),
      unity_facebook: createUnityIcon('facebook'),
      FileAdd: createUnityIcon('file_add'),
      unity_file_add: createUnityIcon('file_add'),
      FileDownload: createUnityIcon('file_download'),
      unity_file_download: createUnityIcon('file_download'),
      FileUpload: createUnityIcon('file_upload'),
      unity_file_upload: createUnityIcon('file_upload'),
      Filter: createUnityIcon('filter'),
      unity_filter: createUnityIcon('filter'),
      FolderAdd: createUnityIcon('folder_add'),
      unity_folder_add: createUnityIcon('folder_add'),
      FolderDownload: createUnityIcon('folder_download'),
      unity_folder_download: createUnityIcon('folder_download'),
      FolderOpen: createUnityIcon('folder_open'),
      unity_folder_open: createUnityIcon('folder_open'),
      FolderUpload: createUnityIcon('folder_upload'),
      unity_folder_upload: createUnityIcon('folder_upload'),
      Folder: createUnityIcon('folder'),
      unity_folder: createUnityIcon('folder'),
      ForwardDup: createUnityIcon('forward'),
      unity_forward: createUnityIcon('forward'),
      FullScreen: createUnityIcon('full_screen'),
      unity_full_screen: createUnityIcon('full_screen'),
      GaugeChart: createUnityIcon('gauge_chart'),
      unity_gauge_chart: createUnityIcon('gauge_chart'),
      Gear: createUnityIcon('gear'),
      unity_gear: createUnityIcon('gear'),
      Globe: createUnityIcon('globe'),
      unity_globe: createUnityIcon('globe'),
      GroupDup: createUnityIcon('group'),
      unity_group: createUnityIcon('group'),
      UserGroups: createUnityIcon('user_groups'),
      unity_user_groups: createUnityIcon('user_groups'),
      Hamburger: createUnityIcon('hamburger'),
      unity_hamburger: createUnityIcon('hamburger'),
      HandDown: createUnityIcon('hand_down'),
      unity_hand_down: createUnityIcon('hand_down'),
      HandLeft: createUnityIcon('hand_left'),
      unity_hand_left: createUnityIcon('hand_left'),
      HandRight: createUnityIcon('hand_right'),
      unity_hand_right: createUnityIcon('hand_right'),
      HandUp: createUnityIcon('hand_up'),
      unity_hand_up: createUnityIcon('hand_up'),
      HashTag: createUnityIcon('hash_tag'),
      unity_hash_tag: createUnityIcon('hash_tag'),
      HeatmapChart: createUnityIcon('heatmap_chart'),
      unity_heatmap_chart: createUnityIcon('heatmap_chart'),
      Help: createUnityIcon('help'),
      unity_help: createUnityIcon('help'),
      History: createUnityIcon('history'),
      unity_history: createUnityIcon('history'),
      Home: createUnityIcon('home'),
      unity_home: createUnityIcon('home'),
      HorizontalBarChart: createUnityIcon('horizontal_bar_chart'),
      unity_horizontal_bar_chart: createUnityIcon('horizontal_bar_chart'),
      HorizontalBracket: createUnityIcon('horizontal_bracket'),
      unity_horizontal_bracket: createUnityIcon('horizontal_bracket'),
      IndentAlt: createUnityIcon('indent_alt'),
      unity_indent_alt: createUnityIcon('indent_alt'),
      Indent: createUnityIcon('indent'),
      unity_indent: createUnityIcon('indent'),
      InfoCircle: createUnityIcon('info_circle'),
      unity_info_circle: createUnityIcon('info_circle'),
      InfoDup: createUnityIcon('info'),
      unity_info: createUnityIcon('info'),
      JobResubmit: createUnityIcon('job_resubmit'),
      unity_job_resubmit: createUnityIcon('job_resubmit'),
      JobRun: createUnityIcon('job_run'),
      unity_job_run: createUnityIcon('job_run'),
      JobSuspend: createUnityIcon('job_suspend'),
      unity_job_suspend: createUnityIcon('job_suspend'),
      Jobs: createUnityIcon('jobs'),
      unity_jobs: createUnityIcon('jobs'),
      Layers: createUnityIcon('layers'),
      unity_layers: createUnityIcon('layers'),
      LeftArrow: createUnityIcon('left_arrow'),
      unity_left_arrow: createUnityIcon('left_arrow'),
      LeftChevron: createUnityIcon('left_chevron'),
      unity_left_chevron: createUnityIcon('left_chevron'),
      LeftSmall: createUnityIcon('left_small'),
      unity_left_small: createUnityIcon('left_small'),
      Left: createUnityIcon('left'),
      unity_left: createUnityIcon('left'),
      Lightning: createUnityIcon('lightning'),
      unity_lightning: createUnityIcon('lightning'),
      AreaChart: createUnityIcon('area_chart'),
      unity_area_chart: createUnityIcon('area_chart'),
      LineChart: createUnityIcon('line_chart'),
      unity_line_chart: createUnityIcon('line_chart'),
      LoadProfile: createUnityIcon('load_profile'),
      unity_load_profile: createUnityIcon('load_profile'),
      LocalSystem: createUnityIcon('local_system'),
      unity_local_system: createUnityIcon('local_system'),
      Lock: createUnityIcon('lock'),
      unity_lock: createUnityIcon('lock'),
      MagicWand: createUnityIcon('magic_wand'),
      unity_magic_wand: createUnityIcon('magic_wand'),
      MapsChart: createUnityIcon('maps_chart'),
      unity_maps_chart: createUnityIcon('maps_chart'),
      Max: createUnityIcon('max'),
      unity_max: createUnityIcon('max'),
      MemoryCard: createUnityIcon('memory_card'),
      unity_memory_card: createUnityIcon('memory_card'),
      Message: createUnityIcon('message'),
      unity_message: createUnityIcon('message'),
      Min: createUnityIcon('min'),
      unity_min: createUnityIcon('min'),
      Minus: createUnityIcon('minus'),
      unity_minus: createUnityIcon('minus'),
      Monitor: createUnityIcon('monitor'),
      unity_monitor: createUnityIcon('monitor'),
      MoveTo: createUnityIcon('move_to'),
      unity_move_to: createUnityIcon('move_to'),
      Move: createUnityIcon('move'),
      unity_move: createUnityIcon('move'),
      Navigate: createUnityIcon('navigate'),
      unity_navigate: createUnityIcon('navigate'),
      Notepad: createUnityIcon('notepad'),
      unity_notepad: createUnityIcon('notepad'),
      Notification: createUnityIcon('notification'),
      unity_notification: createUnityIcon('notification'),
      NumberedList: createUnityIcon('numbered_list'),
      unity_numbered_list: createUnityIcon('numbered_list'),
      Numeral: createUnityIcon('numeral'),
      unity_numeral: createUnityIcon('numeral'),
      OpenNewWindow: createUnityIcon('open_new_window'),
      unity_open_new_window: createUnityIcon('open_new_window'),
      Options: createUnityIcon('options'),
      unity_options: createUnityIcon('options'),
      OrderedList: createUnityIcon('ordered_list'),
      unity_ordered_list: createUnityIcon('ordered_list'),
      Pages: createUnityIcon('pages'),
      unity_pages: createUnityIcon('pages'),
      PieChart: createUnityIcon('pie_chart'),
      unity_pie_chart: createUnityIcon('pie_chart'),
      Placeholder: createUnityIcon('placeholder'),
      unity_placeholder: createUnityIcon('placeholder'),
      PolygonChart: createUnityIcon('polygon_chart'),
      unity_polygon_chart: createUnityIcon('polygon_chart'),
      Powerpoint: createUnityIcon('powerpoint'),
      unity_powerpoint: createUnityIcon('powerpoint'),
      Print: createUnityIcon('print'),
      unity_print: createUnityIcon('print'),
      Properties: createUnityIcon('properties'),
      unity_properties: createUnityIcon('properties'),
      PropertyAlt: createUnityIcon('property_alt'),
      unity_property_alt: createUnityIcon('property_alt'),
      RadarChart: createUnityIcon('radar_chart'),
      unity_radar_chart: createUnityIcon('radar_chart'),
      Redo: createUnityIcon('redo'),
      unity_redo: createUnityIcon('redo'),
      Rewind: createUnityIcon('rewind'),
      unity_rewind: createUnityIcon('rewind'),
      RightArrow: createUnityIcon('right_arrow'),
      unity_right_arrow: createUnityIcon('right_arrow'),
      RightChevron: createUnityIcon('right_chevron'),
      unity_right_chevron: createUnityIcon('right_chevron'),
      RightSmall: createUnityIcon('right_small'),
      unity_right_small: createUnityIcon('right_small'),
      RightDup: createUnityIcon('right'),
      unity_right: createUnityIcon('right'),
      Rupee: createUnityIcon('rupee'),
      unity_rupee: createUnityIcon('rupee'),
      BubbleChart: createUnityIcon('bubble_chart'),
      unity_bubble_chart: createUnityIcon('bubble_chart'),
      Search: createUnityIcon('search'),
      unity_search: createUnityIcon('search'),
      Server: createUnityIcon('server'),
      unity_server: createUnityIcon('server'),
      SessionRemote: createUnityIcon('session_remote'),
      unity_session_remote: createUnityIcon('session_remote'),
      Share: createUnityIcon('share'),
      unity_share: createUnityIcon('share'),
      ShowHide: createUnityIcon('Show_hide'),
      unity_Show_hide: createUnityIcon('Show_hide'),
      SlopeChart: createUnityIcon('slope_chart'),
      unity_slope_chart: createUnityIcon('slope_chart'),
      SplitHorizontal: createUnityIcon('split_horizontal'),
      unity_split_horizontal: createUnityIcon('split_horizontal'),
      SplitVertical: createUnityIcon('split_vertical'),
      unity_split_vertical: createUnityIcon('split_vertical'),
      SquareMarkup: createUnityIcon('square_markup'),
      unity_square_markup: createUnityIcon('square_markup'),
      CheckboxEmpty: createUnityIcon('checkbox_empty'),
      unity_checkbox_empty: createUnityIcon('checkbox_empty'),
      StackOverflow: createUnityIcon('stack_overflow'),
      unity_stack_overflow: createUnityIcon('stack_overflow'),
      StackedBarChart: createUnityIcon('stacked_bar_chart'),
      unity_stacked_bar_chart: createUnityIcon('stacked_bar_chart'),
      StarSelected: createUnityIcon('star_selected'),
      unity_star_selected: createUnityIcon('star_selected'),
      Star: createUnityIcon('star'),
      unity_star: createUnityIcon('star'),
      Std: createUnityIcon('std'),
      unity_std: createUnityIcon('std'),
      StepIn: createUnityIcon('step_in'),
      unity_step_in: createUnityIcon('step_in'),
      StepOut: createUnityIcon('step_out'),
      unity_step_out: createUnityIcon('step_out'),
      Sum: createUnityIcon('sum'),
      unity_sum: createUnityIcon('sum'),
      TableChart: createUnityIcon('table_chart'),
      unity_table_chart: createUnityIcon('table_chart'),
      Terminate: createUnityIcon('terminate'),
      unity_terminate: createUnityIcon('terminate'),
      Text: createUnityIcon('text'),
      unity_text: createUnityIcon('text'),
      ThumbsDown: createUnityIcon('thumbs_down'),
      unity_thumbs_down: createUnityIcon('thumbs_down'),
      ThumbsUp: createUnityIcon('thumbs_up'),
      unity_thumbs_up: createUnityIcon('thumbs_up'),
      TileView: createUnityIcon('tile_view'),
      unity_tile_view: createUnityIcon('tile_view'),
      ToggleOff: createUnityIcon('toggle_off'),
      unity_toggle_off: createUnityIcon('toggle_off'),
      ToggleOn: createUnityIcon('toggle_on'),
      unity_toggle_on: createUnityIcon('toggle_on'),
      TrashCan: createUnityIcon('trash_can'),
      unity_trash_can: createUnityIcon('trash_can'),
      TreeView: createUnityIcon('tree_view'),
      unity_tree_view: createUnityIcon('tree_view'),
      Undo: createUnityIcon('undo'),
      unity_undo: createUnityIcon('undo'),
      Unlock: createUnityIcon('unlock'),
      unity_unlock: createUnityIcon('unlock'),
      UpArrow: createUnityIcon('up_arrow'),
      unity_up_arrow: createUnityIcon('up_arrow'),
      UpChevron: createUnityIcon('up_chevron'),
      unity_up_chevron: createUnityIcon('up_chevron'),
      UpSmall: createUnityIcon('up_small'),
      unity_up_small: createUnityIcon('up_small'),
      Up: createUnityIcon('up'),
      unity_up: createUnityIcon('up'),
      UploadAlt: createUnityIcon('upload_alt'),
      unity_upload_alt: createUnityIcon('upload_alt'),
      UploadAlt1: createUnityIcon('upload_alt1'),
      unity_upload_alt1: createUnityIcon('upload_alt1'),
      Upload: createUnityIcon('upload'),
      unity_upload: createUnityIcon('upload'),
      User: createUnityIcon('user'),
      unity_user: createUnityIcon('user'),
      Var: createUnityIcon('var'),
      unity_var: createUnityIcon('var'),
      View: createUnityIcon('view'),
      unity_view: createUnityIcon('view'),
      WaterfallChart: createUnityIcon('waterfall_chart'),
      unity_waterfall_chart: createUnityIcon('waterfall_chart'),

      // From Template End
    },
  });
}
