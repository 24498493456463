/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ILinkStyleProps, ILinkStyles } from '@fluentui/react';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const LinkStyles = (props: ILinkStyleProps): Partial<ILinkStyles> => {
  const { theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { linkText, linkVisitedText } = extendedSemanticColors;

  return {
    root: {
      color: linkText,
      fontSize: '12px',
      lineHeight: '16px',
      selectors: {
        '&:hover': {
          textDecoration: 'underline',
        },
        '&:visited': {
          color: linkVisitedText,
        },
      },
    },
  };
};
