/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { AOneUnitySemanticColors } from './AOneUnitySemanticColors';

const BaseColors = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',

  BLUE_007199: '#007199',
  BLUE_0078D4: '#0078D4',
  BLUE_00A9E0: '#00A9E0',
  BLUE_00AAE6: '#00AAE6',
  BLUE_0589B4: '#0589B4',
  BLUE_016F95: '#016F95',
  BLUE_005776: '#005776',
  BLUE_2DCCD3: '#2DCCD3',
  BLUE_32A6AC: '#32A6AC',
  BLUE_408199: '#408199',
  BLUE_D9F3F4: '#D9F3F4',
  BLUE_EAF9FA: '#EAF9FA',

  GRAY_333333: '#333333',
  GRAY_484848: '#484848',
  GRAY_4D4D4D: '#4D4D4D',
  GRAY_DADADA: '#DADADA',
  GRAY_888888: '#888888',
  GRAY_999999: '#999999',
  GRAY_A2AAAD: '#A2AAAD',
  GRAY_A6A6A6: '#A6A6A6',
  GRAY_ABB1BB: '#ABB1BB',
  GRAY_EAEAEA: '#EAEAEA',
  GRAY_E1E5E7: '#E1E5E7',
  GRAY_E1DFDD: '#E1DFDD',
  GRAY_E3E3E3: '#E3E3E3',
  GRAY_F1F1F1: '#F1F1F1',
  GRAY_F4F4F4: '#F4F4F4',

  RED_FA4616: '#FA4616',
};

export const CommonSemanticColors = {
  errorText: BaseColors.RED_FA4616,
  input: {
    inputBackgroundChecked: BaseColors.BLUE_2DCCD3,
    inputBackgroundCheckedHovered: BaseColors.BLUE_32A6AC,
    inputBackgroundDisabled: BaseColors.GRAY_F4F4F4,
    inputBackgroundSelected: BaseColors.BLUE_EAF9FA,
    inputBorderDisabled: BaseColors.GRAY_A2AAAD,
    inputBorderFocused: BaseColors.BLUE_2DCCD3,
    inputBorder: BaseColors.GRAY_A6A6A6,
    smallInputBorder: BaseColors.GRAY_A2AAAD,
  },
};

export const LightSemanticColors: AOneUnitySemanticColors = {
  background: BaseColors.WHITE,
  contextualMenu: {
    border: BaseColors.GRAY_EAEAEA,
    color: BaseColors.GRAY_333333,
    iconColor: BaseColors.BLACK,
    disabledColor: BaseColors.GRAY_A6A6A6,
  },
  dialog: {
    text: BaseColors.GRAY_4D4D4D,
    subText: BaseColors.GRAY_333333,
  },
  icon: {
    color: BaseColors.GRAY_333333,
    background: BaseColors.TRANSPARENT,
  },
  label: {
    text: BaseColors.GRAY_333333,
  },
  list: {
    content: {
      border: BaseColors.GRAY_E1E5E7,
      hover: {
        background: BaseColors.BLUE_EAF9FA,
      },
      selected: {
        background: BaseColors.BLUE_EAF9FA,
      },
      selectedHover: {
        background: BaseColors.BLUE_D9F3F4,
      },
      text: BaseColors.GRAY_4D4D4D,
    },
    header: {
      border: BaseColors.GRAY_E1E5E7,
      text: BaseColors.GRAY_4D4D4D,
    },
  },
  link: {
    linkText: BaseColors.BLUE_005776,
    visitedText: BaseColors.BLUE_408199,
  },
  nav: {
    rest: {
      background: BaseColors.GRAY_F1F1F1,
      text: BaseColors.GRAY_4D4D4D,
    },
    selected: {
      background: BaseColors.GRAY_E1DFDD,
      text: BaseColors.BLUE_005776,
    },
  },
  pivot: {
    hover: {
      background: BaseColors.GRAY_F4F4F4,
    },
    rest: {
      text: BaseColors.GRAY_4D4D4D,
    },
    selected: {
      border: BaseColors.BLUE_005776,
      text: BaseColors.BLUE_005776,
    },
  },
  primaryButton: {
    rest: {
      background: BaseColors.BLUE_005776,
      text: BaseColors.WHITE,
      border: BaseColors.BLUE_005776,
    },
    hover: {
      background: BaseColors.WHITE,
      text: BaseColors.BLUE_005776,
      border: BaseColors.BLUE_005776,
    },
    pressed: {
      background: BaseColors.WHITE,
      text: BaseColors.BLUE_408199,
      border: BaseColors.BLUE_408199,
    },
    disabled: {
      background: BaseColors.GRAY_F4F4F4,
      text: BaseColors.GRAY_A6A6A6,
      border: BaseColors.GRAY_F4F4F4,
    },
  },
  progressIndicator: {
    progressBarBackground: BaseColors.BLUE_2DCCD3,
  },

  search: {
    borderFocused: BaseColors.BLUE_0078D4,
  },

  tag: {
    iconColor: BaseColors.BLUE_005776,
    iconBackground: BaseColors.GRAY_DADADA,
  },

  secondaryButton: {
    rest: {
      background: BaseColors.WHITE,
      border: BaseColors.GRAY_A2AAAD,
      text: BaseColors.GRAY_4D4D4D,
    },
    hover: {
      background: BaseColors.WHITE,
      border: BaseColors.BLUE_005776,
      text: BaseColors.BLUE_005776,
    },
    pressed: {
      background: BaseColors.WHITE,
      border: BaseColors.BLUE_408199,
      text: BaseColors.BLUE_408199,
    },
    disabled: {
      background: BaseColors.GRAY_F4F4F4,
      text: BaseColors.GRAY_A6A6A6,
      border: BaseColors.GRAY_F4F4F4,
    },
  },
  commandBarIconStyles: {
    hoverBackground: BaseColors.WHITE,
    rest: BaseColors.BLACK,
    disabled: BaseColors.GRAY_A6A6A6,
  },
};
