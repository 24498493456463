/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { IButtonStyles } from '@fluentui/react';
import { ITheme } from '@fluentui/theme';
import * as StyleConstants from '../Constants';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

export const DefaultButtonStyles = (theme: ITheme): Partial<IButtonStyles> => {
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const {
    defaultButtonBackground,
    defaultButtonBorder,
    defaultButtonText,
    defaultButtonBackgroundHovered,
    defaultButtonBorderHovered,
    defaultButtonTextHovered,
    defaultButtonBackgroundPressed,
    defaultButtonBorderPressed,
    defaultButtonTextPressed,
    defaultButtonBackgroundDisabled,
    defaultButtonBorderDisabled,
    defaultButtonTextDisabled,
  } = extendedSemanticColors;

  return {
    root: {
      borderRadius: StyleConstants.defaultButtonHeight / 2,
      padding: '8px 16px',
      background: defaultButtonBackground,
      color: defaultButtonText,
      border: `${StyleConstants.borderWidth} solid ${defaultButtonBorder}`,
    },

    rootHovered: {
      background: defaultButtonBackgroundHovered,
      border: `${StyleConstants.borderWidth} solid ${defaultButtonBorderHovered}`,
      color: defaultButtonTextHovered,
    },
    rootPressed: {
      background: defaultButtonBackgroundPressed,
      border: `${StyleConstants.borderWidth} solid ${defaultButtonBorderPressed}`,
      color: defaultButtonTextPressed,
    },
    rootDisabled: {
      background: defaultButtonBackgroundDisabled,
      border: `${StyleConstants.borderWidth} solid ${defaultButtonBorderDisabled}`,
      color: defaultButtonTextDisabled,
    },
    label: [
      {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: StyleConstants.defaultButtonHeight / 2,
        margin: 0,
        padding: 0,
      },
    ],

    menuIcon: {
      marginRight: 0,
      fontSize: 12,
      lineHieght: 12,
    },
  };
};
