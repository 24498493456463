/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { getPlaceholderStyles } from '@fluentui/style-utilities';
import { ExtendedSemanticColors } from '../ExtendedSemanticColors';

const placeHolderStyles = {
  fontSize: 12,
  lineHeight: 16,
};

export const fieldStyles = (disabled: boolean | undefined, inputBackgroundSelected: string, errorText: string, extendedSemanticColors: ExtendedSemanticColors) => [
  {
    fontSize: 12,
    lineHeight: 16,
    padding: '4px 6px 4px 4px',
    color: extendedSemanticColors.inputText,
    selectors: {
      '::selection': {
        background: inputBackgroundSelected,
      },
      '::after': { color: errorText },
    },
  },
  disabled && {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  getPlaceholderStyles(placeHolderStyles),
];

export const TextFieldStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  const { disabled, focused, theme } = props;
  const { semanticColors } = theme;
  const extendedSemanticColors = semanticColors as ExtendedSemanticColors;

  const { errorText, inputBorderDisabled, inputBorderFocused, inputBackgroundSelected } = extendedSemanticColors;

  return {
    fieldGroup: [
      {
        selectors: {
          '::after': {
            borderColor: inputBorderFocused,
            borderWidth: 1,
          },
        },
        height: 24,
        lineHeight: 22,
        fontSize: 11,
        borderRadius: 2,
      },
      disabled && {
        backgroundColor: semanticColors.disabledBackground,
        borderColor: inputBorderDisabled,
      },
      focused && {
        borderColor: inputBorderFocused,
      },
    ],
    field: fieldStyles(disabled, inputBackgroundSelected, errorText, extendedSemanticColors),
    subComponentStyles: {
      label: {
        root: {
          color: extendedSemanticColors.inputText,
          fontSize: 12,
          lineHeight: 16,
          padding: '4px 0px',
        },
      },
    },

    errorMessage: {
      color: errorText,
    },
  };
};
